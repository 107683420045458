<template>
    <!-- 关于我们列表 -->
    <div class="about grey-bg">
        <div class="about-con">
            <div class="about-item space-between" v-for="item in aboutList.contents" :key="item.id" @click="goAboutShow(item.id)">
                <div class="about-title">{{ item.title }}</div>
                <img class="about-img" src="~assets/images/user/more-icon.png" />
            </div>
        </div>
        <div class="sour-talk">
            <div class="sour-talk-con vertical-center">
                <img class="sour-talk-img" src="~assets/images/user/sour-talk.png" />
                <div class="sour-talk-text">酸谈社群</div>
                <div class="sour-talk-edition">版本号：1.0.0</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "about",
    data(){
        return{
            aboutList: []
        }
    },
    created() {
        this.getAbout()
    },
    methods:{
        // 获取帮助反馈
        getAbout(){
            let _this = this
            let url = _this.$api.Contents+`?type=abouts`
            _this.$http.get(url,true).then( res => {
                if(res.data.success){
                    _this.aboutList = res.data.data
                }
            } )
        },
        goAboutShow(id){
            this.$router.push("/user/about/show/" + id)
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "~assets/scss/user/about/index.scss";
</style>